import React from 'react';
import { Dropdown, DropdownOptionProps } from 'wix-ui-tpa/Dropdown';
import { Text } from 'wix-ui-tpa/Text';
import { InjectedTranslateProps, translate } from 'react-i18next';
import { ApiTypes } from '@wix/social-groups-api/dist/src/types';
import { AppRootActions } from '../App/types';
import { st, classes } from './SortBy.st.css';
import { DATA_HOOKS } from './dataHooks';
import {
  BIUserEntry,
  tryToCallBi,
  withBiLogger,
  WithBiLoggerProps,
} from '@wix/social-groups-common/dist/src/context';
import {
  withErrorLogger,
  WithErrorLoggerProps,
} from '@wix/social-groups-common/dist/src/context/error-logger';
import { compose } from '@wix/social-groups-common/dist/src/compose';

interface SortingBoxProps {
  onChange: AppRootActions['sortGroups'];
  initialSelectedId: string;
  className?: string;
}

const sortByKeys: { [key in ApiTypes.v1.GroupsSortBy]: string } = {
  [ApiTypes.v1.GroupsSortBy.RECENT_ACTIVITY]:
    'groups-web.group-list.sort.recent-activity',
  [ApiTypes.v1.GroupsSortBy.NAME]: 'groups-web.group-list.sort.alphabetical',
  [ApiTypes.v1.GroupsSortBy.MEMBERS_COUNT]:
    'groups-web.group-list.sort.member-count',
};

type Props = SortingBoxProps &
  InjectedTranslateProps &
  WithBiLoggerProps &
  WithErrorLoggerProps;

class SortByComponent extends React.Component<Props> {
  onChange = (option: DropdownOptionProps) => {
    try {
      tryToCallBi(async () => {
        await this.props.biLogger.groupsChoseGroupsSortBy({
          sort_by: option.id,
          origin: 'popup_menu',
          userEntry: BIUserEntry.SITE,
        });
      });
      this.props.onChange(option.id as ApiTypes.v1.GroupsSortBy);
    } catch (e) {
      console.warn('No `onChange` in props');
      this.props.logger.captureException(e);
    }
  };

  render() {
    const { t, className } = this.props;

    const options: DropdownOptionProps[] = Object.keys(sortByKeys).map(
      (id) => ({
        id,
        value: t(sortByKeys[id]),
        isSelectable: true,
      }),
    );
    // dropdown is not updated on props.initialSelectedId https://github.com/wix/wix-ui-tpa/pull/356
    return (
      <div className={st(classes.root, {}, className)}>
        <Text className={classes.label}>{t('groups-web.group-list.sort')}</Text>
        <Dropdown
          className={st(classes.dropdown, {}, className)}
          placement={'bottom'}
          data-hook={DATA_HOOKS.sortDropdown}
          onChange={this.onChange}
          options={options}
          initialSelectedId={this.props.initialSelectedId}
        />
      </div>
    );
  }
}

const enhance = compose(translate(), withBiLogger, withErrorLogger);

export const SortBy = enhance(
  SortByComponent,
) as React.ComponentType<SortingBoxProps>;
