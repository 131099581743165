import React from 'react';
import { Search } from '@wix/social-groups-common/dist/src/components';
import { withTPAConfig } from '@wix/social-groups-common/dist/src/components/withTPAConfig';
import { SortBy } from './SortBy';
import { DATA_HOOKS } from './dataHooks';
import { st, classes } from './SearchSortingBox.st.css';
import { AppRootActions } from '../App/types';
import { InjectedTranslateProps, translate } from 'react-i18next';
import { compose } from '@wix/social-groups-common/dist/src/compose';

export interface SearchSortingBoxProps {
  onSearch: AppRootActions['searchGroups'];
  onSort: AppRootActions['sortGroups'];
  initialSortSelectedId: string;
}

export const SearchSortingBoxComponent: React.FC<
  SearchSortingBoxProps &
    InjectedTranslateProps & {
      mobile: boolean;
    }
> = ({ mobile, t, onSort, initialSortSelectedId, onSearch }) => (
  <div
    className={st(classes.root, { mobile })}
    data-hook={DATA_HOOKS.searchSortingBox}
  >
    <SortBy
      onChange={onSort}
      initialSelectedId={initialSortSelectedId}
      className={classes.sortBy}
    />
    <Search
      withCloseButton={true}
      placeholder={t('groups-web.search.placeholder')}
      onChange={onSearch}
      className={classes.search}
    />
  </div>
);

const enhance = compose(translate(), withTPAConfig);

export const SearchSortingBox = enhance(
  SearchSortingBoxComponent,
) as React.ComponentType<SearchSortingBoxProps>;
